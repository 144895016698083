import React from 'react';
import { graphql } from 'gatsby';

import Hero from '../components/Hero';
import Layout from '../components/Layout/Layout';
import { Container } from '../components/Grid';
import Band from '../components/Band/Band';
import Typography from '../components/Typography';
import Contact from '../components/Contact/Contact';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO';

export default ({ data }) => (
    <Layout>
        <SEO
            title="Enhance your career"
            description="We achieve success when we match ambitions, values, talent and opportunities. We can help help you enhance your career"
        />
        <Hero mobileImage={data.mobileImage} desktopImage={data.desktopImage} logoColor="white" />
        <Band size="md">
            <Container>
                <Typography color="indigo">
                    <PageHeader as="h1">
                        We achieve success when we match ambitions, values, talent and opportunities
                    </PageHeader>
                    <p>
                        If you think we might be able to help you enhance your career, send us your current cv and some
                        examples of your work to the email address below.
                    </p>
                    <Contact heading={null} />
                </Typography>
            </Container>
        </Band>
    </Layout>
);

export const query = graphql`
    query ContactPageQuery {
        desktopImage: file(relativePath: { eq: "unsplash-I4YsI1zWq_w.jpg" }) {
            ...HeroBackgroundImage_Desktop
        }
        mobileImage: file(relativePath: { eq: "unsplash-sm-I4YsI1zWq_w.jpg" }) {
            ...HeroBackgroundImage_Mobile
        }
    }
`;
